<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    dropdownMode="closeInactive"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <div class="app-logo">
      <router-link to="/">
        <img src="/img/h24logo.png">
      </router-link>
    </div>
    <AgentProfile />
    <CRenderFunction flat :content-to-render="nav"/>
  </CSidebar>
</template>

<script>
import nav from './_nav'
import AgentProfile from './AgentProfile'

export default {
  name: 'TheSidebar',
  components: {
    AgentProfile
  },
  //nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    nav() {
      let setNav = JSON.parse(JSON.stringify(nav))
      setNav[0]._children[0].items = setNav[0]._children[0].items.filter((m)=>{
        if(this.$store.state.userProfile.canAddAgent) {
          return true
        }else {
          return m.to !== '/manage-account/create/agent'
        }
      })
      return setNav
    }
  }
}
</script>

<style lang="scss">
.app-logo {
  img {
    max-width: 100%;
  }
}
</style>
