<template>
  <CHeader fixed with-subheader light>
    <!--<CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo"/>
    </CHeaderBrand>-->
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <span class="c-header-nav-link text-primary" @click="isShowMarketModal=true">
          <i class="fas fa-database mr-2 fa-lg"></i>
          <span>{{groupTitle}}</span>
          <i class="fas fa-angle-double-right ml-2 mr-2 text-secondary"></i>
          <span>{{marketTitle}}</span>
          <i class="fas fa-caret-down ml-2"></i>
        </span>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <div class="c-header-nav-link text-success" @click="isShowRoundModal=true">
          <i class="fas fa-calendar-alt mr-2 fa-lg"></i>
          <span class="round-date" v-if="!isLoading">
            <span>{{roundDate}}</span>
            <i class="fas fa-caret-down ml-2"></i>
          </span>
          <div v-else class="d-flex">
            <b-spinner small label="Small Spinner" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
            <b-spinner small label="Small Spinner" class="ml-1" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
            <b-spinner small label="Small Spinner" class="ml-1" variant="info"></b-spinner>
            <b-spinner small label="Small Spinner" type="grow" class="ml-1" variant="success"></b-spinner>
          </div>
        </div>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-auto">

      <!--
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open"/>
        </CHeaderNavLink>
      </CHeaderNavItem>
      -->

      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <!--<CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>-->

    <GlobalMarketModal :isShow="isShowMarketModal" @close="closeMarketModal" />
    <GlobalRoundModal :isShow="isShowRoundModal" @close="closeRoundModal" />
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import GlobalMarketModal from '@/views/components/GlobalMarketModal'
import GlobalRoundModal from '@/views/components/GlobalRoundModal'

import SystemConfigService from '@/services/SystemConfigService'
import MarketService from '@/services/MarketService'

import moment from '@/helpers/moment'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
    GlobalMarketModal,
    GlobalRoundModal
  },
  data() {
    return {
      isShowMarketModal: false,
      isShowRoundModal: false,
      isLoading: false
    }
  },
  computed: {
    globalMarket() {
      return this.$store.state.globalMarket
    },
    currentGroupId() {
      return this.globalMarket.groupId
    },
    currentMarketId() {
      return this.globalMarket.marketId
    },
    groupTitle() {
      const group = this.$store.state.marketGroups.find(group=>group._id===this.currentGroupId)
      return group?.groupTitle
    },
    marketTitle() {
      const market = this.$store.state.markets.find(market=>market._id===this.currentMarketId)
      return market?.marketTitle
    },
    markets() {
      return this.$store.state.markets
    },
    roundDate() {
      if(!this.globalMarket.round.roundDate)
        return '--/--/----'

      return moment(this.globalMarket.round.roundDate.date).format("DD/MM/YYYY")
    }
  },
  watch: {
    currentGroupId() {
      if(!this.currentMarketId) {
        this.setStartMarketId()
      }
    },
    currentMarketId() {
      this.$store.commit('updateGlobalMarket', {
        round: {
          roundId: null,
          roundDate: null
        }
      })
      this.getMarketRound()
    },
    markets(n, o) {
      if(o.length === 0 && !this.currentMarketId) {
        this.setStartMarketId()
      }
    }
  },
  methods: {
    closeMarketModal() {
      this.isShowMarketModal = false
    },
    closeRoundModal() {
      this.isShowRoundModal = false
    },
    setStartMarketId() {
      const marketsInGroup = this.markets.filter((market)=>{
        return market.groupId === this.currentGroupId
      })
      if(marketsInGroup.length) {
        this.$store.commit('updateGlobalMarket', {
          marketId: marketsInGroup[0]._id
        })
      }
    },
    getMarketRound() {
      this.isLoading =true
      MarketService.getRound(this.currentMarketId)
      .then((response)=>{
        if(response.success) {
          // console.log(response.data)
          if(response.data) {
            this.$store.commit('updateGlobalMarket', {
              round: {
                roundId: response.data._id,
                roundDate: response.data.roundDate
              }
            })
          }
        }else{
          throw new Error(response?.message)
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
  }
}
</script>
<style lang="scss">
.c-header-nav-link {
  cursor: pointer;
  span {
    font-size: 130%;
    span {
      font-size: 100%;
    }
  }
}
</style>
