export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'หน้าหลัก',
        to: '/',
        fontIcon: 'fas fa-home',
        items: []
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงานการแทง',
        route: '/setting-report',
        fontIcon: 'far fa-file-alt',
        items: [
          {
            name: 'รอผลเดิมพัน',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/wait'
          },
          {
            name: 'ดูของรวม',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/overall'
          },
          {
            name: 'คาดคะเน ได้-เสีย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/win-lose'
          },
          {
            name: 'ดูของสมาชิก/ประเภท',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/member'
          },
          {
            name: 'รายการโพย/ยกเลิกโพย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/tickets'
          },
          {
            name: 'เลขอั้น',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/limit'
          },
          {
            name: 'ตั้งค่ารับของ (เฉพาะงวด)',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/round-setting'
          },
          {
            name: 'ตั้งค่ารับของ (ตามชนิด)',
            fontIcon: 'fas fa-angle-double-right',
            to: '/setting-report/market-receive'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงานแพ้ชนะ',
        route: '/win-lose-report',
        fontIcon: 'fas fa-list-ol',
        items: [{
          name: 'แพ้-ชนะ สุทธิ',
          fontIcon: 'fas fa-angle-double-right',
          to: '/win-lose-report/market'
        }, {
          name: 'แพ้-ชนะ สมาชิก/ประเภท',
          fontIcon: 'fas fa-angle-double-right',
          to: '/win-lose-report/member'
        }, {
          name: 'โพยที่ถูกรางวัล',
          fontIcon: 'fas fa-angle-double-right',
          to: '/win-lose-report/won-tickets'
        }, {
          name: 'ผลการออกรางวัล',
          fontIcon: 'fas fa-angle-double-right',
          to: '/win-lose-report/results'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'รายงานบัญชี',
        route: '/account-report',
        fontIcon: 'fas fa-search-dollar',
        items: [{
          name: 'รายงานการเงิน',
          fontIcon: 'fas fa-angle-double-right',
          to: '/account-report/finance'
        }, {
          name: 'การเงินสมาชิก',
          fontIcon: 'fas fa-angle-double-right',
          to: '/account-report/finance-member'
        }, {
          name: 'ประวัติการเข้าใช้งาน',
          fontIcon: 'fas fa-angle-double-right',
          to: '/account-report/log'
        }]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการสมาชิก',
        route: '/manage-account',
        icon: 'cil-user',
        items: [
          {
            name: 'เพิ่มเมมเบอร์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/create/member'
          },
          {
            name: 'เพิ่มเอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/create/agent'
          },
          {
            name: 'รายชื่อสมาชิก',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/member'
          },
          {
            name: 'รายชื่อที่ถูกระงับ',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/block'
          },
          {
            name: 'ตั้งค่าสมาชิกใหม่',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/setting'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าสมาชิก',
        route: '/account-setting',
        fontIcon: 'fas fa-user-cog',
        items: [
          {
            name: 'เปิด/ปิด หวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/account-setting/group'
          },
          {
            name: 'แบ่งหุ้น/เก็บของ',
            fontIcon: 'fas fa-angle-double-right',
            to: '/account-setting/sharing'
          },
          {
            name: 'เปิด/ปิด/ตั้งค่า อัตราจ่าย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/account-setting/payout'
          },
          {
            name: 'ขั้นต่ำ/สูงสุด/สูงสุดต่อเลข',
            fontIcon: 'fas fa-angle-double-right',
            to: '/account-setting/bet'
          }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'ออกจากระบบ',
        to: '/logout',
        fontIcon: 'fas fa-sign-out-alt',
        items: []
      }
    ]
  }
]
