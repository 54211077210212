<template>
  <b-modal
    ref="global-round-modal"
    hide-footer
    title="เลือกงวดหวย"
    @show="resetModal"
    @hidden="resetModal"
    size="xl"
    class="global-round-modal"
  >

    <div v-if="isLoading" class="modal-body-loading d-flex align-items-center justify-content-center">
      <b-spinner large label="Large Spinner" variant="info"></b-spinner>
    </div>
    <div v-else-if="!items.length">
      ไม่มีงวดหวย
    </div>
    <div v-else class="row no-gutters">
      <div
        v-for="item in items"
        class="col-md-4 p-2"
        :key="item._id"
      >
        <div class="card mb-0 pointer" @click="selectRound(item)">
          <div class="card-body p-2" :class="item.cssClass.card">
            <div class="d-flex justify-content-between">
              <div>{{ item.roundDate.date | roundDate }}</div>
              <div>
                <span class="badge font-weight-normal" :class="item.cssClass.badge">{{ item.cssClass.text }}</span>
              </div>
              <div>
                <small>
                  <i class="far fa-clock text-success"></i>
                  {{ item.roundDate.close | closeTime }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </b-modal>
</template>
<script>
import MarketService from '@/services/MarketService'

import moment from '@/helpers/moment'

export default {
  name: 'GlobalRoundModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      rounds: []
    }
  },
  computed: {
    currentMarketId() {
      return this.$store.state.globalMarket.marketId
    },
    currentRoundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    items() {
      return this.rounds.map((round)=>{

        const cssClass = {
          card: 'alert-secondary',
          badge: 'badge-secondary',
          text: ''
        }

        if(round.status === 'Open') {
          if(moment().isBefore(round.roundDate.open)) {
            cssClass.card = 'alert-warning'
            cssClass.badge = 'badge-warning'
            cssClass.text = 'รอเปิดแทง'
          }else
          if(moment().isBefore(round.roundDate.close)) {
            cssClass.badge = 'badge-success'
            cssClass.card = 'alert-success'
            cssClass.text = 'เปิดรับแทง'
          }else{
            cssClass.text = 'ปิดรับแทง'
          }
        }else
        if(round.status === 'Close') {
          cssClass.text = 'ปิดรับแทง'
        }else
        if(round.status === 'Cancel') {
          cssClass.badge = 'badge-danger'
          cssClass.text = 'ยกเลิก'
        }else
        if(round.status === 'Refund') {
          cssClass.badge = 'badge-primary'
          cssClass.text = 'คืนเงิน'
        }else
        if(round.status === 'Complete') {
          cssClass.badge = 'badge-info'
          cssClass.text = 'กำลังออกผล'
        }else
        if(round.status === 'Paid') {
          cssClass.badge = 'badge-primary'
          cssClass.text = 'จ่ายเงินแล้ว'
        }

        if(this.currentRoundId === round._id) {
          cssClass.card = 'alert-primary round-active'
        }

        return {
          ...round,
          cssClass: cssClass
        }
      })
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    resetModal() {},
    showModal() {
      this.loadRounds()
      this.$refs['global-round-modal'].show()
    },
    hideModal() {
      this.$refs['global-round-modal'].hide()
    },
    loadRounds() {
      this.rounds = []
      this.isLoading = true
      MarketService.getRounds(this.currentMarketId)
      .then((response)=>{
        if(response.success) {
          this.rounds = response.data
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    selectRound(round) {
      console.log(round)
      this.$store.commit('updateGlobalMarket', {
        round: {
          roundId: round._id,
          roundDate: round.roundDate
        }
      })
      this.$refs['global-round-modal'].hide()
    },
    isCurrentRound(roundId) {
      return this.currentRoundId === roundId
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  },
  filters: {
    roundDate(date) {
      if(!date)
        return '--/--/----'

      return moment(date).format("DD/MM/YYYY")
    },
    closeTime(date) {
      if(!date)
        return '--/--/----'

      return moment(date).add(543, 'y').format("DD MMM YY HH:mm")
    }
  }
}
</script>
<style lang="scss">
.pointer {
  cursor: pointer;
}

.round-active {
  border: 2px solid #007bff;
  border-radius: 2px;
}

.modal-body-loading {
  min-height: 100px;
}
</style>
